<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{title}}
        </div>
        <div class="formBody">
          <!-- 表单 -->
<!--          <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>-->
          <FormulaSeven :columns="listData" :data="sevenData" :select-index="selectIndex" @change="sevenChange"></FormulaSeven>
          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
            <van-button class="formula-template-button" block type="info" @click="submit" style="width:90px">提交</van-button>
          </div>
          <!-- 计算结果 -->
<!--          <div class="CalculationResultsBox" v-if="countText || countText !== ''">-->
<!--            <div class="CalculationResults"><span>计算结果</span></div>-->
<!--            &lt;!&ndash; 具体标准 &ndash;&gt;-->
<!--            <div>-->
<!--              <div class="CalculationDetail"><span></span>{{result}}分 {{countText}}</div>-->
<!--            </div>-->
<!--            &lt;!&ndash;            <div>&ndash;&gt;-->
<!--            &lt;!&ndash;              <div class="CalculationDetail"><span></span>具体标准：</div>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults :countText="countTextT" :countTextList="[]"></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          title="应激指数"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>

  </div>

</template>

<script>
import FormulaBanner from '@/components/FormulaBanner.vue'
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import FormulaSeven from "@/components/Template7/FormulaSeven";
import CalculationResults from '@/components/CalculationResults'
export default {
  name: "medicalSearch",
  data(){
    return{
      compute:true,
      countTextT: '',
      listSelect: {},
      selectIndex: null,
      sevenData: [
        {
          tezheng: '无活动性红斑',
          ganjuesangshi: '总胆红素<2mg/dl',
          yundongsangshi: '无症状或间接性恶心、呕吐或厌食',
          dongmai: '成人:<500ml/d或3次/d<br/>' +
              '儿童:<10ml/(kg·d)或<4次/d',
          // jingmai: '探及',
          // chuli: '择期',
        },
        {
          tezheng: '皮疹面积<25%',
          ganjuesangshi: '总胆红素2~3mg/dl',
          yundongsangshi: '持续性恶心、呕吐或厌食',
          dongmai: '成人:<1000ml/d或3~4次/d<br/>' +
              '儿童:<20ml/(kg·d)或4~6次/d',
          // jingmai: '探及',
          // chuli: '数天',
        },
        {
          tezheng: '皮疹面积25%-50%',
          ganjuesangshi: '总胆红素3.1-6mg/dl',
          yundongsangshi: '',
          dongmai: '成人:<1500ml/d或5-7次/d<br/>'+
            '儿童:< 30ml/(kg·d)或7-10次/d',
          // jingmai: '探及',
          // chuli: '立即复流',
        },
        {
          ganjuesangshi: '皮疹面积>50%',
          tezheng: '总胆红素6.1~15mg/dl',
          yundongsangshi: '',
          dongmai: '成人:>1500ml/d或>7次/d<br/>'+
              '儿童:> 30ml/(kg·d)或>10次/d',
          // jingmai: '消失',
          // chuli: '不可逆缺血(除外病程<3h)',
        },
        {
          ganjuesangshi: '全身红斑面积>50%<br/>' +
              '伴水疱或表皮剥脱面积(>5%)',
          tezheng: '总胆红素>15mg/dl',
          yundongsangshi: '',
          dongmai: '严重腹痛伴或不伴肠梗阻或便血',
          // jingmai: '消失',
          // chuli: '不可逆缺血(除外病程<3h)',
        }
      ],
      listData: [
        {
          label: '皮疹(活动性红斑)',
          prop: 'tezheng',
          width: 250,
        },
        {
          label: '肝脏(总胆红素)',
            prop: 'ganjuesangshi',
            width: 250,
        },{
          label: '上消化道',
            prop: 'yundongsangshi',
            width: 250,
        },{
          label: '下消化道(排便)',
            prop: 'dongmai',
            width: 250,
        },
      ],
      tableData: {},
      formulaCode: '',
      shengao: '',
      yingjizhishu: '',
      title: '',
      memberId: '1111',
      selectValue: '',
      selectValueSix: [],
      username:"",
      value:"",
      showPopover: false,
      showPicker: false,
      columns: ['正常或轻伤', '中度创伤', '重度创伤'],
      nianling: '',
      xingbie: null,
      countText: '',
      patientId: null,
      result: null,
      yingjizhishuzhi: '',
      tizhong: '',
      xueqingnanongdu: '',
      fenzhiList: [],
    }
  },
  components: {
    FormulaBanner,
    FormulaExplain,
    FormulaReferences,
    FormulaSeven,
    CalculationResults
  },
  async created() {
    await this.test()
    await this.getTableData()
  },

  methods:{
    sevenChange (row, index) {
      this.listSelect = row
      this.selectIndex = index
    },
    coumputTetxt () {
      switch (this.selectIndex) {
        case 0:
          this.result = '0级'
            this.countText = '无任何器官1~4级损伤'
              break;
        case 1:
          this.result = 'Ⅰ级'
            this.countText = '1~2级皮疹,不累及肝脏、消化道'
              break;
        case 2:
          this.result = 'Ⅱ级'
            this.countText = '3级皮疹和(或)1级肝脏和(或)1级消化道症状'
              break;
        case 3:
          this.result = 'Ⅲ级'
            this.countText = '0~3级皮疹和(或)0~1级上消化道,2~3级肝脏和(或)2~3级下消化道'
              break;
          case 4:
              this.result = 'Ⅳ级'
              this.countText = '4级皮疹、肝脏或下消化道损伤,0~1级上消化道损伤'
      }
      this.countTextT = this.result +':\xa0\xa0' +this.countText
    },
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'RJGSZAJL',
      }
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
        this.title = res.data.chineseName
      }
      if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId
        }
        const res = await getFormulaHuiXian(data)
        if (res.code === 0) {
          switch (res.data.result.value) {
            case '0级':
              this.selectIndex = 0
              break;
            case 'Ⅰ级':
              this.selectIndex = 1
              break;
            case 'Ⅱ级':
              this.selectIndex = 2
              break;
            case 'Ⅲ级':
              this.selectIndex = 3
                  break
              case 'Ⅳ级':
                  this.selectIndex = 4
          }
          this.coumputTetxt()
        }
      }
    },
    async submit () {
      // if (!this.listSelect.length || this.listSelect.length === 0) {
      //   Toast.fail('请至少选择一项')
      //   return
      // }
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          // data: this.listSelect
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          value: this.result,
          result: this.countText,
          DisplayResults:this.countTextT
        }
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u){
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
    yingjiClick () {
      this.showPicker = true
    },
    onConfirm(value) {
      this.yingjizhishu = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    async onSubmit(){
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shengao: {value: this.shengao, unit: 'cm'},
          tizhong: {value: this.tizhong, unit: 'kg'},
          nianling: {value: this.nianling, unit: '岁'},
          xingbie: {value: this.xingbie == 1 ? '男' : '女'},
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {}
      }
      if (this.xingbie == 1) {
        data.result.nanxing = {value: this.result, unit: 'kcal'}
      } else {
        data.result.nvxing = {value: this.result, unit: 'kcal'}
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    userChange (data) {
      if (data.birthday && data.birthday !== '') {
        this.nianling = this.calculateAge(data.birthday)
      } else {
        this.nianling = ''
      }
      this.xingbie = data.sex
      this.patientId = data.id
      this.selectValue = data
    },
  }
}
</script>


<style scoped lang="scss">
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  min-height: 100vh;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding:15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding-bottom:10px;
    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
      width:60px
    }
    .unit{
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size:16px;
      width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding:0px 0px;
}
::v-deep .van-field__control{
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2{
  width:100%;
  padding: 15px 10px;
  padding-bottom:1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references{
    margin-bottom:15px
  }
}
</style>
